.icon-remove {
    height: 34px;
    margin: 5px 0;
    width: 10%;
}

.register-wrapper {
    left: 40%;
    width: 60%;
}

.full-width {
    width: 100%;
    background-color: var(--blue) !important;
    color: white !important;
}

body>#root {
    width: inherit;
    overflow-y: hidden;
}

.three-dots {
    overflow: hidden !important;
    width: 100% !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
}

#firstPopular {
    background-size: cover !important;
}




.remove-btn-click-styles:focus {
    background-color: var(--blue) !important;
}

.remove-btn-click-styles-red:focus {
    background-color: var(--red) !important;
}

.remove-btn-click-styles-green:focus {
    background-color: green !important;
}

.post-card-image {
    min-width: -webkit-fill-available;
}


.card-post-title {
    margin-left: 10px;
    font-size: 24px;
    color: lightgray;
    max-width: inherit;
}


.card-post-image {
    position: relative;
}


.post-details-image {
    max-width: 100%;
    position: relative;
    width: -webkit-fill-available;
    transition: all 2s ease-in-out;
}

.post-details-image:hover {
    cursor: pointer;
}

.card {

    transition: all 0.2s ease-in-out;
}

@font-face {
    font-family: 'TitleFont';
    src: url('../font-families/Roboto-Regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../font-families/Roboto-Regular.woff') format('woff'),
}

.title {
    padding: 3%;
}


.card {
    position: relative;
}

.date-added {
    margin-top: 4px !important;
    font-weight: 100;
}

.mb-negative{
    margin-bottom: -30px;
    font-size: 18px;
}


.mb-negative-one{
    margin-bottom: -5px;
    font-size: 18px;
}

.search-input {
    max-width: 20%;
    border: none;
    background-color: inherit;
    border-bottom: 1px solid gray;
    border-radius: 0;
    margin-left: 1%;
    padding-bottom: 0;
    padding-left: 0;
    display: inline;
}

.search-input:focus {
    border: none;
    background-color: inherit;
    border-bottom: 1px solid black;

}

.small-image {
    height: 80%;
    max-width: 80%;
}


.small-image-container {
    border: 1px solid lightgray;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 5px;
}

.image-flex-container {
    display: flex;
    /* justify-content: center; */
    flex-wrap: wrap;
}

.active-image {
    border: 1px solid var(--blue);
}

.card>.header {
    display: flex;
    justify-content: space-between;
}

.card>.header>h2 {
    margin: 0;
}

.hr-line {
    border-top: 1px solid #707070;
    /* height: 1px; */
    width: 95%;
    margin-left: 2.5%;
    opacity: 0.3;
}

@media only screen and (max-width:768px) {
    .About-us-content {
        padding: 5% 6% 6% 11% !important;
    }

    .separate-line {
        margin-top: 30px;
    }

    .post-tag {
        padding: 1.1% 2.5vw !important;
    }

    .post-type-button-wrapper {
        /* flex-direction: column; */
        align-items: center;
    }

    .post-type-button-wrapper>div,
    .post-type-button-wrapper>div>* {
        width: 30%;
        height: 20px;

    }

    .post-type-button-wrapper {
        justify-content: unset !important;
    }

    .post-type-button-wrapper>div {
        display: flex;
        justify-content: center;
    }

    .post-type-button-wrapper button {
        font-size: unset;
    }
}

.post-tag {
    background-color: lightgray;
    margin: 0 0.3vw;
    padding: 0.7% 0.5vw;
    border-radius: 20px;
    font-size: small;
    color: black;
}

.post-tag:hover {
    background-color: gray;
    color: white;
    cursor: pointer;
}

.wrapper,
#root {
    width: 100vw !important;
}

.post-tags {
    padding-left: 3%;
}

.tag-text {
    margin-left: 2%;
}

.cancel-btn {
    font-size: small;
    padding-top: 2px;

}

.success-btn:active,
.success-btn:focus {
    background-color: green !important;
    color: white !important;
}

.success-btn:hover {
    background-color: #088f01 !important;
    color: white !important;
    transition: all 0.2s ease-in-out;
}

.success-btn:disabled {
    background-color: green !important;
}

.navbar>.container {
    display: flex;
    width: inherit !important;
}

.nav-flex>.nav {
    display: flex;
    justify-content: center;
}

.black>a {
    color: black !important;
    border-radius: 0 !important;
    border-bottom: 2px solid white;
}

.alink>a,
.alink:hover,
.blink>a:hover {
    color: var(--blue) !important;
    border-bottom: 2px solid var(--blue);
    transition: all 0.3s ease-in-out;
    border-radius: 0 !important;
    text-decoration: none !important;
}

@font-face {
    font-family: "NotoSansJp-Regular";
    src: url('../font-families/NotoSansJP-Regular.otf') format('opentype'),
}

* {
    font-family: NotoSansJp-Regular, sans-serif !important;
}

:root {
    --purple: #8734F9;
    --orange: #F97134;
    --blue: rgb(52, 109, 255);
    --gray: rgb(74, 74, 74);
    --red: rgb(255, 0, 0);
    --light-gray: rgb(177, 183, 199);
    --background: rgb(239, 241, 244);
    --light-background: rgb(239, 241, 244);
    --street-blue: rgb(3, 39, 101);
    --icon-blue: rgb(57, 80, 162);
}

.About-us-content {
    height: inherit;
    padding: 10% 45% 5% 11%
}


.video-player {
    top: 6%
}

.shadow-thick {
    background-color: rgba(0, 0, 0, .8) !important;
}

.patundshmeri {
    background-image: url('../img/main.png');
}

.gastronomi {
    background-image: url('../img/gastronomi.png');
}

.vetura {
    background-image: url('../img/car.jpg');
}

.About-us-main {
    transition: 0.3s ease-in-out;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-position: center;
    background-size: cover;
}

.about-us-button {
    /* border-bottom: 2px solid var(--blue) !important; */
    opacity: 1 !important;
    transition: 0.3s ease-in-out;
    border: 1px solid var(--light-gray);
}

.about-us-button>a {

    color: white !important;
}

.about-us-button:hover,
.about-us-button:active,
.about-us-button:focus,
.about-us-button>a:hover {
    background-color: rgba(255, 255, 255, 0.3) !important;
    /* color: var(--blue); */
    transition: 0.3s ease-in-out;

}

.line-divider {
    margin: 5px 0;
    height: 1px;
    border-bottom: 1px solid var(--gray);
    width: 90%;
    position: relative;
    left: 5%;
    opacity: 0.5;
}

.blue-text-hover:hover,
.blue-text-hover:active,
.blue-text-hover:focus {
    color: var(--blue);
    background-color: var(--light-gray);
}

.background-tinted {
    background-color: var(--background);
}

.radio-size {
    width: 20px;
    height: 20px;

}

.ease {
    transition: 0.3s ease-in-out;
}

.label-padding {
    padding: 4px !important;
}

.text-align-center {
    vertical-align: middle;
}

.add-listing-button,
.add-listing-button:hover,
.add-listing-button:active,
.add-listing-button:focus,
.active-button {
    color: white;
    background-color: var(--blue);
    border: none !important
}

.active-button {
    opacity: 1 !important;
}

.hover-bg-blue:hover,
.hover-bg-blue:active,
.hover-bg-blue:focus {
    background-color: var(--blue) !important;
    color: white;
}

input[type=submit]:focus,
input[type=submit]:hover,
input[type=submit]:active,
input[type=submit] {
    color: white;
    background-color: var(--blue);
    border: none;
}

.z-5 {
    z-index: 5;
}

.z-0 {
    z-index: 0;
}

.test {
    /* transform: translateZ(20%); */
    background-color: var(--blue) !important;
    transform: translateX(-200px) rotate(5deg);
    height: 20px;
    width: 120vw;
    position: absolute;
    z-index: 1;
}

.line-first {
    width: 80%;
}


.line-last {
    width: 80%;
    left: 40%;
}

.test-parent {
    z-index: 1;
    width: 100%;
    height: 30px
}

.footer-links:hover,
.footer-links:focus,
.footer-links:active {
    color: white;
    transition: all 0.1s ease-in-out;
}

.post:hover {
    box-shadow: 0px 3px 4px 5px #aaaaaa;
    transition: all 0.1s ease-in-out;
}

/* .rec-item-wrapper:hover {
    ;
} */

.post {
    transition: all 0.1s ease-in;
}

.bSWeAW:hover:enabled,
.bSWeAW:focus:enabled,
.cetQhm {
    background-color: var(--blue) !important;
}

.cetQhm {
    box-shadow: 0 0 1px 3px var(--blue) !important;
}

.bSWeAW,
.hSiCVM {
    box-shadow: none !important;
}

.rec-carousel-wrapper,
.rec-carousel,
.rec-slider-container,
.rec-slider,
.rec-item-wrapper {
    height: 100% !important;
}

.rec-item-wrapper {
    padding: 10px !important;
}

@media only screen and (max-width:1150px) {
    .services-list {
        width: 90% !important;
        left: 5% !important;
    }
}

.scrolling {
    overflow-y: auto !important;

}

.scrolling::-webkit-scrollbar {
    display: block;
}

.loginpage {
    position: absolute;
}



.login-page {
    background-image: url('../img/login-photo.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}

.About-us-main-shadow {
    background-color: rgba(0, 0, 0, .55);
}

input:focus {
    border: none !important;
    outline-style: none;
}

a:hover,
a:active,
a:focus {
    background-color: transparent !important;
    /* border: none !important; */
}

.add-post-div {
    height: 50px;
}



.form-control,
.form-control:focus {
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3 !important;
    border-radius: 4px;
    color: #565656;
    padding: 8px 12px;
    /* height: 40px; */
    box-shadow: none;
}

.a-padding-10-15 {
    padding: 10px 15px;
}

.form-control:focus {
    box-shadow: none !important;
}

.account-dropdown {
    border: 1px solid var(--blue);
    border-radius: 4px;

}

.account-dropdown>div:first-child {
    margin: auto;
}

.post-list>.card-post-div-superior {
    flex-shrink: 0;
    min-width: 350px;
    min-height: 565px;
    width: 31%;
    max-width: 40%;
    margin: 15px auto;
}

@media only screen and (min-width:768px) {
    .input-search {
        color: var(--blue) !important;
    }
}

.card-post-div-superior {
    width: 60%;
}

.card-post-div-superior-preview {
    width: 100%;
}

.price-container {
    background-color: var(--blue);
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
}

@media only screen and (max-width:450px) {
    .card-post-div-superior {
        flex-basis: 100% !important;
        min-height: 60vh !important;
        width: 80% !important;
    }

    .dashboard-slide .card-post-div-superior {
        width: 100% !important;
    }

    .footer-form {
        width: 100%;
    }

    .contact-us-links {
        width: 100% !important;
    }

    .stay-up-to-date {
        margin: auto !important;
    }



    .padding-top-10 {
        padding-top: 10px;
        margin: 0 !important;
    }

    .padding-top-10>div {
        height: 125px !important;
    }

    .padding-top-10>div>* {
        margin: 0;
    }

    .padding-top-10>div>form>div {
        height: 50px;
    }
}

.file-input:focus-visible,
.file-input:focus {
    outline: none !important;
}

.main-add-post>div>.small-image {
    max-width: 100% !important;
    width: 100% !important;
    justify-content: center;
}

.margin-bottom-0 {
    margin-bottom: 0 !important;
}

.tag-div {
    padding: 5px 10px;
}

.tag-div>.clickable {
    width: 22px;
    height: 22px;
}

.slick-active>button::before {
    color: var(--blue) !important;
    /* outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px ; */
}

.owner-image {
    width: 70px;
    height: 70px;
    border-radius: 4px;
}

.ml-10-px {
    margin-left: 10px;
}

@media only screen and (max-width:992px) {

    .custom-button {
        width: 50vw !important;
    }

    .modal-style {
        width: 80vw !important;
        left: 10vw !important;
    }

    .post-title-custom {
        height: 200px !important;
    }

    textarea {
        min-width: 100% !important;
    }

    .services-list>.service {
        height: 45%;
    }

    .main-add-post {
        flex-direction: column;
    }

    li>.nav-bar-links {
        padding: 1% 1.5% !important;
    }

    .overview-section {
        height: 40vh !important;
    }

    .overview-details>div {
        height: 18%;
    }

    .description-section,
    .amenities-section {
        height: auto !important;
    }

    .left-section>div {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .overview-details {
        flex-wrap: wrap;
    }

    .overview-details>div {
        width: 35%;
    }

    .post-main-content {
        flex-direction: column;
    }

    .post-main-content>div {
        width: 100%;
    }

    .post-tag {
        width: 15% !important;
    }

    .alink::before,
    .alink>a::before,
    .blink>a::before {
        top: 20px
    }

    .alink::after,
    .alink>a::after,
    .blink>a::after {
        top: 20px
    }

    .About-us-content {
        padding: 14% 46% 6% 11%;
    }

    .search-container {
        width: 35% !important;
    }
}

.responsive-design {
    width: 100%;
    position: absolute;
    bottom: 13vh;
    left: 0;
    height: 38vh !important;
}

.search-responsive {
    height: 100% !important;
}

.ham {
    z-index: 2;
}

.input-text:focus {
    height: 100% !important;
}

.modal-style {
    position: absolute;
    width: 60vw;
    height: 20vh;
    left: 20vw;
    top: 40vh;
    background-color: var(--blue);
    color: white
}

.textarea-group textarea {
    max-width: 45vw;
    max-height: 100%;
    min-width: 45vw;
    min-height: 100%;
}

.textarea-group>div {
    height: 90%;
}

.textarea-group {
    height: 160px;
}

.modal-style:focus {
    outline: none;
}

#root {
    overflow-x: hidden;
}

.search-container>form>.form-group {
    height: 35px;
    min-height: 35px;
}

.search-container * {
    color: black !important
}

.search-container {
    height: 80%;
    top: 20%;
    width: 25%;
    left: 60%;
    border-radius: 10px;
}

.search-container {
    position: absolute;
}

.About-us-main,
.About-us-main-shadow {
    min-height: 480px;
}

.services {
    min-height: 350px;
}

.main-add-post>div:nth-child(2) {
    min-height: 665px;
}

.main-add-post>div:nth-child(2)>.card-post-div-superior {
    min-height: 595px !important
}

.main-container {
    min-height: 465px;
}

.paid-ad-posts {
    min-height: 595px;
}

.city-checkbox {
    min-height: 35px !important;
    height: 35px !important;
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
    padding-left: 13px;
}

.pad-left>div>div {
    padding-left: 12%;
}

.city-checkbox>div {
    padding-left: 1.5%;
}

.city-checkbox>input {
    padding-left: 1.5% !important;
}

.pad-left>div>input {
    padding-left: 12% !important;
}

.checkbox-inp {
    min-width: 15px;
    width: 33%
}

.checkbox-inp>div {
    margin-left: 5px !important;
}

.contact-details>* {
    line-height: 1.5;
}

@media only screen and (max-width:1100px) {
    .padding-side-removed {
        padding-left: 0;
        padding-right: 0;
    }

    .padding-side-removed>a {

        padding-left: 0;
        padding-right: 0;
    }
}

.pagination-li-page,
.previous>a,
.next>a {
    border-radius: 4px;

}

.pagination-page {
    border-radius: 4px;
    border: none;
}

.pagination-page:focus,
.pagination-page:hover,
.next>a:hover,
.next>a:focus,
.previous>a:hover {
    background-color: var(--blue) !important;
    color: white !important;
    outline: none !important;
}

.pagination>li>a {
    border: none !important;
    margin-left: 1px !important;
}

.pagination-li-active {}

.pagination-active {
    background-color: var(--blue) !important;
    color: white !important;
}

.width-fit-content {
    min-width: fit-content !important;
}

.about-us-main-text>div>* {
    line-height: 1.8;
}

.slider-dot-list>li>button {
    opacity: 0;
}

.slick-active {
    position: relative
}

.slick-active::marker {
    outline: 1px solid red !important;
    width: 10px;
    height: 10px;
    color: blue;
    position: absolute;
    left: 100%
}

.profile-image-div {
    border: none;
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.profile-pic {
    border-radius: 50%;
    border: 1px solid lightgray;
    /* border: 0; */
}

.about-us-main-text {
    height: auto !important;
}

@media only screen and (max-width:600px) {

    .login-form,
    .register-form {
        width: 85% !important;
    }

    .login-form>div,
    .register-form>div {
        width: 100%;
    }

    .text-container>h1 {
        margin-bottom: 10px !important;
    }

    .slick-slider>.slick-list {
        overflow: visible !important;
    }

    .BrainhubCarousel__track>.BrainhubCarouselItem {
        width: 100% !important;
    }

    .s-one>div {
        width: 100% !important;
    }

    .s-three>div {
        width: 100%;
    }

    /* .slick-slide {
        margin: 0 10px;
        width: 80vw !important;
    } */

    .s-two>div>div>div>div,
    .s-two>div>div>div>div>ul,
    .s-two>div>div,
    .s-two>div>div>div {
        width: 100%;
        height: 100%;
    }


    .s-two>div>div>div>div>ul>li {
        height: 100%;
        /* width: 100% !important; */
        margin: 0 10px;
    }

    .about-us-main-text>div {
        width: 90% !important;
    }

    .about-us-main-text {
        height: auto !important;
    }

    .rec-slider-container {
        overflow: inherit !important;
    }

    .archive-posts-button {
        width: 55%;
        min-width: 192px;
        height: 10%;
    }

    .post-list-head {
        flex-direction: column;
    }

    .paid-ad-posts {
        height: 95vh !important;
    }

    .paid-ad-posts>div {
        height: 90%;
        top: 5%
    }

    .trending-apartments {
        height: 95vh !important;
    }

    .slider-wrap {
        width: 85% !important;
    }

    .trending-apartments>div {
        height: 95%;
        top: 2.5%;
    }

    .About-us-main {
        padding: 9% 3%;
        height: 90vh !important;
    }

    .About-us-main-shadow {
        height: 90vh !important;
    }

    .links-sidebar {
        z-index: 1;
        background-color: var(--blue);
        height: 92vh;
        top: 8vh;
        width: 60%;
        transition: all 0.3s ease-in-out;
        /* left: 0; */
        right: 100%;
    }

    .nav-header-responsive {
        justify-content: space-around !important;
        flex-direction: column;
        width: 100%;
        transition: all 0.3s ease-in-out;
        justify-content: normal !important;
    }

    .links-sidebar-active {
        right: 40% !important;
        justify-content: normal !important;
        transition: all 0.3s ease 0s;
    }

    .nav-header-responsive-active {

        right: 40% !important;
        justify-content: normal !important;
        transition: all 0.3s ease 0s;
    }


    .alink::before,
    .alink>a::before,
    .blink>a::before {
        display: none;
    }

    .alink::after,
    .alink>a::after,
    .blink>a::after {
        display: none;
    }

    .alink>a,
    .blink>a {
        margin: auto;
        text-align: center;
        font-size: 25px;
        color: white !important;
        border: none;
    }

    .alink,
    .blink {
        padding: 0 !important;
        justify-content: center;
        display: flex;
        font-size: 25px;
        height: 10%;
        color: white !important;
        border: none;
    }

    .blink {
        border-bottom: 1px solid white;
    }

    .alink:hover,
    .blink>a:hover {
        color: white !important;
    }

    .grouper-container {
        flex-direction: column;
    }

    .text-container {
        /* justify-content: space-around; */
        /* overflow: hidden; */
        height: 158px !important;
    }

    .text-container>.divider {
        display: none !important;
    }


    .main-container {
        height: auto !important;
    }

    .grouper-container>div {
        margin: 20px 0;
    }

    footer>div {
        flex-direction: column !important;

    }

    footer {
        height: auto !important;
    }

    .left-side {
        width: 100% !important;
    }

    .right-side {
        width: 100% !important;
        flex-direction: row;
        margin-top: 20px
    }

    .right-side>div {
        flex-direction: row;
    }

    .login-wrapper,
    .register-wrapper,
    .login-wrap-container {
        width: 100% !important;
        left: 0% !important;
        /* height: 70% !important; */
        /* top: 0% !important; */
    }

    .login-page {
        /* padding: 3%; */
    }

    .search-input-checkbox-div {
        width: 40% !important;
        justify-content: center;
        margin: 0 !important;
    }

    .location-input:focus,
    .form-control:focus {
        height: 100% !important;
        outline: hidden;
        outline-offset: 0;
    }

    .search-input-checkbox-div>div {
        min-width: 30%;
    }

    .search-input-checkbox-div>label {
        margin: 0 !important;
    }

    .post-main-content {
        flex-direction: column;
    }

    .post-main-content>div {
        width: 100%;
    }

    .post-tag {
        width: 15% !important;
    }

    .description-section,
    .amenities-section {
        height: auto !important;
    }

    .overview-details {
        flex-wrap: wrap;
    }

    .overview-details>div {
        width: 35%;
    }

    .About-us-main {
        background-attachment: scroll;
    }

    .shto-postim {
        padding-left: 0;
        padding-right: 0;
    }

    .shto-postim-div {
        width: 25%;
    }

}

@media only screen and (max-width:400px)  {
    .About-us-main {
        padding: 5% 3%;
        height: 90vh !important;
    }
}
.shto-postim {
    width: 42%;
}

.carousel-root {
    height: 80%;
}

.carousel-root>.carousel-slider {
    height: 100%;
}

.account-dropdown {
    border-radius: 4px !important;
}

.account-dropdown>.divider {
    margin: 0 !important;
    padding-left: 5%;
    border-radius: 4px;
}

.label-text-add-post {
    width: 60px !important;
}



.input-div-add-post {
    height: 15px !important;
    width: 15px !important;

}

.input-div-add-post>input {
    height: 100%;

}

@media only screen and (max-width:350px) {

    .login-form,
    .register-form {
        width: 80% !important;
    }

    .input-div-add-post {
        margin-left: 10px;
    }

    .modal-style {
        height: 30vh !important;
    }
}

.w-100-im {
    min-width: 100% !important;
}

@media only screen and (max-width:350px) {
    .responsive-design>.search-responsive {
        width: 70% !important;
        left: 15% !important;
    }
}


@media only screen and (max-width:400px) {
    /* .input-div-add-post {
        width: 5% !important;
    } */

    .custom-button {
        width: 90vw !important;
    }

    .sitemap {
        max-height: 150px !important;
    }

    .sitemap>.list-wrapper {
        height: 150px !important;
    }

    .list-wrapper>.list-footer {
        height: 110px !important;
        max-height: 110px !important;
    }

    .label-text-add-post {
        /* width: 20% !important; */
    }

    .file-input {
        width: 100% !important;
    }

    .input-submit {
        width: 40% !important;
    }

    .llogaria-button {
        width: 100% !important;
    }

}

.llogaria-button {
    width: 40%;
    margin-right: 5px;
}

.nav-bar-links>.menu {
    position: absolute !important;
    top: 4vh !important;
}

.big-image {
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-position: center;
    background-size: contain;
}

.popular-div-images {
    background-size: cover;
}

.zoom-icon {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    top: 10% !important
}

.photo-slider-post-details {
    /* top: 80%; */
    align-content: center;
    bottom: 0 !important;
    height: 20%;
    width: 100%;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    max-width: 100%;
    position: fixed;
    transition: all 0.2s ease-in-out
}

.top-100 {
    bottom: -100% !important;
    transition: all 0.3s ease-in-out;
}

.photo-slider-post-details>span {
    margin: 10px 0;

    height: max-content;
}

.checkboxes-responsive {
    margin-left: 5px;
    min-height: 10px !important;
    height: 10px !important;
    width: 14px !important;
    min-width: 14px !important;
}

.photo-slider-post-details>.small-image-container {
    border: 3px solid lightgray;
}

.photo-slider-post-details>.active-image {
    border: 3px solid var(--blue)
}

.photo-slider-post-details>span>img {}

.post-title-custom {
    height: 95px;
}

.post-main-content>.p-3 {
    padding: 20px 3%;
}

.input-location-post-details {
    font: 15px;
}

.fill-black {
    fill: black;
    background-color: white;
    border-radius: 50%;
    right: 0
}

.created-button {
    position: absolute;
    z-index: 10;
}



.sitemap *,
.contact-us-list-footer * {
    line-height: 1.5;
}

.left-section>.overview-section,
.right>.agent-section {
    margin-top: 0 !important;
}

.nav-bar-links:hover {
    border: 0 !important;
}

.left-wrapper,
.right-wrapper {
    padding-top: 50px !important;
}

.contact-divs>textarea {
    max-width: 100%;
    max-height: 100%;
    min-width: 100%;
    min-height: 100%;
    border-color: lightgray;
    border-radius: 4px;
}

.send-message {
    height: 40px;
}

.contact-divs>input {
    height: 40px;
}

.contact-icon {
    width: 25px;
    height: 25px;
    color: var(--blue);
    margin-right: 10px
}

.close-icon {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    color: white;
}

footer {
    position: sticky;
    top: 100%;
    width: 100%;
}

.full-view {
    height: 42vh;
}

.sidebar-z {
    z-index: 24 !important;
}

.left-section.w-70.flex.vertical-flex.p-3>.flex.vertical-flex.pl-3.post-title-custom {
    height: auto !important;
}

.bg-green {
    background-color: green;
}

.card-dots-posts-page {
    width: 60% !important;
}

.flex.vertical-flex.card-post.post-card-color.inherit-all>.relative.w-90.left-5.h-20>.flex.flex-space-between.align-center.m-5-perc {
    margin-bottom: 0 !important;
}

.relative.w-90.left-5.h-20>.overflow-hidden.text-overflow-ellipsis.m-0.light-gray {
    position: absolute;
    bottom: 0;
}

.wrapper>div:nth-child(2) {
    min-height: 40px;
}

.photo-slider-post-details>span {
    margin-top: 10px;
    margin-bottom: 0;
}

.photo-slider-post-details {
    justify-content: normal;
    align-content: flex-start;
}

.font-12 {
    font-size: 12px;
}

.flex.wrap.m-vertical-5-px.align-center>.mr-10-px {
    margin-right: 10px !important;
}

.contact-form-post-details input:focus {
    height: 38.8px !important;
}

.contact-form-post-details input:last-child:focus {
    height: 42px !important;
}

.card-post-title-dots {
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.fancy-post-title-dots {
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.card-dots-posts-page {
    -webkit-line-clamp: 3 !important;
}


.left-section.w-70.flex.vertical-flex.p-3>.flex.vertical-flex.pl-3.post-title-custom {
    height: auto !important;
}

.bg-image-about-us {
    background-image: url('../img/main.png');
}

.created-date {
    width: 160px;
    min-width: 160px;
    padding-left: 18px;
}

.created-date>span {
    font-size: 12px;
}

.agencyFormGroup {
    justify-content: flex-start !important;
    align-items: center;
    margin-left: 0 !important;
}

.remove-photo-btn {
    min-height: 30px;
}

.phoneFormGroup {
    min-width: 145px
}

.agencyFormGroup>div {
    margin-left: 10px;
    height: 15px;
    width: 15px;
    min-width: 15px;
    margin-bottom: 6px;
}

.agencyNameFormGroup {
    min-width: 180px;
}

.popular-div-images {
    background-image: url('../img/image1TEST.jpg');
    background-size: contain !important;
    background-repeat: no-repeat;
    border: 1px solid var(--blue);
    background-color: white;
}

@media only screen and (max-width:1100px) and (min-width:900px) {
    .middle {
        width: 200vw;
    }

    .line-last {
        width: 115%;
        left: 77%
    }

    .line-first {
        margin-top: -25px;
        width: 72%;
    }

    .blank-space {
        margin-top: -40px;
    }
}

@media only screen and (max-width:1300px) and (min-width:1100px) {
    .middle {
        width: 200vw;
    }

    .line-last {
        width: 115%;
        left: 72%
    }

    .line-first {
        margin-top: -35px;
        width: 69%;
    }

    .blank-space {
        margin-top: -40px;
    }
}

@media only screen and (max-width:500px) and (min-width:400px) {
    .middle {
        width: 200vw;
    }

    .line-last {
        width: 135%;
        left: 100%
    }

    .line-first {
        margin-top: 5px;
        width: 97%;
    }

    .blank-space {
        margin-top: -23px;
    }
}

@media only screen and (max-width:400px) and (min-width:350px) {
    .middle {
        width: 200vw;
    }

    .line-last {
        width: 135%;
        left: 100%
    }

    .test {
        height: 15px;
    }

    .line-first {
        margin-top: 5px;
        width: 97%;
    }

    .blank-space {
        margin-top: -17px;
    }
}

@media only screen and (max-width:350px) and (min-width:300px) {
    .middle {
        width: 200vw;
    }

    .line-last {
        width: 112%;
        left: 122%
    }

    .test {
        height: 15px;
    }

    .line-first {
        margin-top: 9px;
        width: 113%;
    }

    .blank-space {
        margin-top: -17px;
    }
}

@media only screen and (max-width:300px) and (min-width:250px) {
    .middle {
        width: 200vw;
    }

    .line-last {
        width: 119%;
        left: 132%
    }

    .test {
        height: 15px;
    }

    .line-first {
        margin-top: 12px;
        width: 125%;
    }

    .blank-space {
        margin-top: -17px;
    }
}

@media only screen and (max-width:900px) and (min-width:700px) {
    .middle {
        width: 200vw;
    }

    .line-last {
        width: 115%;
        left: 84%
    }

    .blank-space {
        margin-top: -40px;
    }

    .line-first {
        margin-top: -10px;
    }
}

.fancy-desc>* {
    height: 24px;
    min-height: 24px;
    display: flex;
    align-items: center;
    fill: gray !important
}

.fancy-desc svg>g {
    fill: gray !important;
}

.fancy-desc svg {
    margin-bottom: 5px;
}

@media only screen and (max-width:700px) {
    .video-player>div {
        width: 80vw !important;
        height: 20vh !important;
    }
}

@media only screen and (max-width:700px) and (min-width:500px) {
    .middle {
        width: 200vw;
    }

    .line-last {
        width: 140%;
        left: 88%
    }

    .blank-space {
        margin-top: -40px;
    }
}

.lines {
    z-index: 2;
}

.contact-us-footer {
    width: 60%;
}

.image-input {
    height: 45px;
    margin: 10px 0;
}

.confirmation-modal-div>div>button {
    min-width: 85px;
}

.confirmation-modal-div {
    text-align: center;
}

.confirmation-modal-div>div {
    min-width: 200px;
}

.images-small>span {
    height: 80% !important;
}

.overflow-show {
    overflow: visible !important;
}

.eye-icon {
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
}

@media only screen and (max-width:768px) {

    .About-us-main-shadow,
    .About-us-main {

        height: 90vh !important;

    }

    .big-image {
        height: 82vh !important;
    }

    .overview-details {
        align-content: space-around !important;
    }

    .overview-section {
        height: auto !important;
    }

    .overview-details>div {
        margin: 5px 0 !important;
    }

    .small-image {
        /* bottom: -5% !important; */
    }

    .contact-us-footer {
        justify-content: normal !important;
        height: 250px !important;
    }

    .h-75>.contact-us-list-footer {
        height: 300px !important;
    }

    .responsive-design {
        width: 100%;
        position: absolute;
        bottom: 1vh;
        left: 0;
        height: 50vh !important;
    }

    .contact-us-footer>.h-75 {
        height: 85% !important;
    }

    .h-450 {
        height: 80vh !important;
    }

    .footer-wrapper {
        height: 95% !important;
    }

    .responsive-design {
        min-height: 190px;
        height: 190px;
    }

    /* .modal-style {
        height: 50vh;
    } */

    .modal-style>div {
        flex-direction: column;
    }

    .user-profile-wrapper {
        flex-direction: column-reverse;
    }

    .user-profile-wrapper>form,
    .user-profile-wrapper>div {
        width: 96% !important;
        margin: 5px 0;
        left: 2%
    }



    .footer-wrapper {
        top: 2vh !important;
    }

    .contact-us-list-footer {
        height: 100px !important;
    }

    .sqmeter-icon {
        fill: var(--street-blue) !important;
    }

    .sitemap {
        position: relative;
        /* left: 5%; */
        width: 25%;
        min-width: 100%;
        max-height: 135px;
        padding: 0 !important;
        justify-content: normal !important;
    }

    .list-wrapper {
        height: 135px !important;
    }

    .list-footer {
        flex-wrap: wrap !important;
        height: 80px !important;
        max-height: 100px !important;
    }

    .footer-links {
        margin: 6px 0 !important;

    }

    .list-footer>.footer-links {
        width: 30% !important;
    }

    .contact-us-footer {
        width: 100% !important;
    }


    .svg-image {
        width: 100% !important;
        height: 100px;
    }

    .svg-image>svg {
        height: 100%;
    }

    .services-list {
        flex-direction: column;
    }

    .services-list>div {
        margin: 10px 0 !important;
        padding: 2%;
    }

    .services {
        height: auto !important;
    }

    .left-wrapper,
    .right-wrapper {
        padding-top: 10px !important;
    }

    .contact-divs {
        flex-direction: column;
    }

    .contact-divs>input {
        width: 100% !important;
    }

    .contact-divs>input:last-child {
        margin-top: 15px !important;
    }

    .contact-wrapper {
        flex-direction: column;
    }

    .contact-wrapper-div {
        height: auto !important;
    }

    .contact-wrapper>div {
        width: 100% !important;
        margin: 15px 0;
    }

    .user-profile {
        flex-direction: column;
        height: auto !important;

    }

    .user-profile>.search-input-checkbox-div {
        margin-left: 0 !important;
        width: 100% !important;
        margin: 10px 0 !important;
    }

    .bg-blue {
        background-color: var(--street-blue) !important;
    }

    .blue {
        color: var(--street-blue) !important;
    }

    .links-sidebar {
        background-color: var(--icon-blue) !important;
    }

    .login-page>.login-wrap-container,
    .login-page>.register-wrapper {
        width: 100%;
        left: 0%;
    }

    .alink::before,
    .alink>a::before,
    .blink>a::before {
        display: none;
    }

    .alink::after,
    .alink>a::after,
    .blink>a::after {
        display: none;
    }

    .alink>a,
    .blink>a {
        margin: auto;
        text-align: center;
        font-size: 25px;
        color: white !important;
        border: none;
    }

    .alink,
    .blink {
        padding: 0 !important;
        justify-content: center;
        display: flex;
        font-size: 25px;
        height: 10%;
        color: white !important;
        border: none;
    }

    .blink {
        border-bottom: 1px solid white;
    }

    .alink:hover,
    .blink>a:hover {
        color: white !important;
    }

    .links-sidebar {
        z-index: 1;
        background-color: var(--blue);
        height: 92vh;
        top: 8vh;
        width: 60%;
        transition: all 0.3s ease-in-out;
        /* left: 0; */
        right: 100%;
    }

    .nav-header-responsive {
        /* position: absolute;
        z-index: 1; */
        /* right: 100%; */
        /* top: 8vh;
        height: 92vh !important; */
        justify-content: space-around !important;
        flex-direction: column;
        width: 100%;
        transition: all 0.3s ease-in-out;
        justify-content: normal !important;
    }

    .links-sidebar-active {
        right: 40% !important;
        justify-content: normal !important;
        transition: all 0.3s ease 0s;
    }

    .nav-header-responsive-active {

        right: 40% !important;
        justify-content: normal !important;
        transition: all 0.3s ease 0s;
    }

    .main-container,
    .grouper-container {
        height: auto !important;
    }

    .colored-box {
        display: none;
    }

    /* .blue-box */

    .grouper-container {
        flex-direction: column;
    }

    .latest-container,
    .popular-container {
        height: 80vh;
        width: 100% !important;
    }

    .latest-container>.popular-div-images,
    .popular-container>.popular-div-images {
        height: 65% !important;
        width: 90%;
        left: 5%;
    }

    .latest-container>.text-container,
    .popular-container>.text-container {
        position: absolute !important;
        top: 65%;
        height: 35% !important;
    }

    .grouper-container>div {
        margin: 20px 0 !important;
    }

    .latest-container>img,
    .popular-container>img {
        height: 40%;

    }

    .text-container {
        height: 50% !important;
        top: 5%;
        position: relative !important;

        left: 5%;
    }

    .popular-container>.text-container,
    .latest-container>.text-container {
        width: 90% !important;
    }

    .alink::before,
    .alink>a::before,
    .blink>a::before {
        display: none;
    }

    .alink::after,
    .alink>a::after,
    .blink>a::after {
        display: none;
    }


}

.shadow-bg {
    transition: opacity 0.1s ease-in-out;

}

.popular-iamge-div {
    width: 92%;
    height: 92%;
    left: 4%;
    top: 4%;
}

.delete-image {
    top: -10px;
    right: -11px;
    width: 20px;
    height: 20px;
}

.delete-image:hover {
    cursor: pointer;
}

.rec-dot_active {
    background-color: var(--blue) !important;
    box-shadow: 0 0 1px 3px rgb(52, 109, 255) !important;
}

.rec-pagination>.rec-dot {
    /* box-shadow: 0 0 1px 3px var(--blue) !important; */
    background-color: black;
    height: 5px;
    width: 5px;

}



.checkbox-add:focus {
    height: 40px !important;
}

.left-167 {
    left: 167% !important
}

.transition {
    transition: all 0.3s ease-in-out;
}

.min-width-18-px {
    min-width: 18px
}

.sqmeter-wrapper,
.sqmeter-icon {
    height: 20px !important;
    width: 20px !important;
}

.checkbox-add:focus {
    height: 100% !important;
    outline: none;
}

textarea {
    padding: 8px 12px;
}

textarea:focus-visible {
    outline: none;
}

.post-details-slider * {
    height: 100% !important;
}

.iamge-to-click {
    z-index: 2;
    position: absolute;
    width: 200px;
    height: 200px !important;
}

.slick-slider,
.slick-list,
.slick-track,
.slick-slide,
.slick-slide>div,
.slider-wrapper,
.slider-wrapper>ul,
.carousel,
.carousel-inner,
.carousel-item {
    height: 100%;
}

.slick-list {
    overflow: hidden !important;
}

.slick-slide {
    padding: 5px !important;
}


.carousel-inner {
    display: flex;
}

.carousel-item {
    min-width: 32.5%;
    width: 32.5%;
    margin: 0 5px;
}

.image-zoomed {
    width: 100vw !important;
    height: 100vh !important;
    top: 0 !important;
    object-fit: contain !important;
    transform: none !important;
}

.image-zoomed::after {
    width: 50px;
    height: 50px;
    background-color: black;
    position: absolute;
    z-index: 10;
    right: 0;
    top: 0;
}

.show-more {
    min-width: 127px;
    align-self: center;
}

.register-agency-checkbox:focus,
.register-agency-checkbox:focus-visible {
    outline: none !important;
    outline-offset: 0 !important;
}

/* .slick-slide,
.slider-wrapper>ul>li {
    width: 30vw !important;
    margin: 0px 5px !important;
} */

.slick-track {
    display: flex;
}

.slider-wrapper,
.-slider {
    overflow: inherit !important;
}

.BrainhubCarousel,
.BrainhubCarousel__trackContainer,
.BrainhubCarousel__track {
    height: 100% !important;
    overflow: inherit !important;
}

.BrainhubCarouselItem {
    min-width: 30vw !important;
    width: 30vw !important;
}

.input-div-add-post.input-radio-add>input {
    outline: none !important;
    height: 100% !important;
}



@media only screen and (max-width:768px) {

    .border-white.h-100,
    .bg-white.w-20.radius-top-left-4.radius-bottom-left-4.h-100.flex.align-center.light-gray {
        min-height: 34px !important;
    }

    .border-white {
        min-height: 34px !important;
    }

    .input-radio-add {
        min-height: 25px !important
    }

    .ui.fluid.item.search.selection.dropdown.h-20.flex.align-center,
    .ui.dropdown.flex.align-center.account-dropdown.w-100.bg-white.h-20.no-border.light-gray.radius-top-right-4.radius-bottom-right-4.mt-3 {
        max-height: 34px;
    }
}

.border-white.h-100,
.bg-white.w-20.radius-top-left-4.radius-bottom-left-4.h-100.flex.align-center.light-gray {
    min-height: 25px
}

.custom-arrow {
    width: 40px;
    height: 40px;
    z-index: 2 !important;
    position: absolute;
    background-color: lightgray;
    border-radius: 50%;
    top: 45%;
    left: 2%;
    padding: 1%;
}

.opacity-image-hide {
    opacity: 0;
}

.opacity-image {
    position: absolute;
    opacity: 0;
    transition: opacity 0.37s ease-out;

}

.opacity-image-show {
    opacity: 1;
    transition: all 0.35s ease-in-out;
}

.custom-arrow-next {
    left: 90%
}

.border-white {
    min-height: 25px
}

.form-control:focus {
    /* height: 34px !important; */
}

.image-height {
    position: absolute;
    width: 100%;
    object-fit: contain;
    height: 100%;
}

.home-group {
    border: 1px solid lightslategray;
}

.city-checkbox>div:first-child {
    padding-left: 12% !important;
}

.image-zoomer>.react-transform-component,
.slick-slide>div>.react-transform-component {
    position: absolute;
    z-index: 1;
    width: 100%;
    transition: all 0.5s ease-in-out;
}

.slick-slide>div>.react-transform-component {
    height: 100%;
}

.image-zoomer>.react-transform-component>.react-transform-element,
.slick-slide>div>.react-transform-component>.react-transform-element {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

}

.image-zoomer>.slick-slider {
    height: 85%;
}

.image-zoomer>.slick-slider>.slick-arrow {
    position: absolute;
    z-index: 5;
}

.image-zoomer>.slick-slider>.slick-prev {
    left: 5%
}

.image-zoomer>.slick-slider>.slick-next {
    left: 95%
}

.image-zoomer>.slick-slider>.slick-arrow::before {
    font-size: 35px;

}

.slick-slide>div>.react-transform-component {
    opacity: 0;
}

.slick-active>div>.react-transform-component {
    opacity: 1 !important;
}

@media only screen and (max-width:768px) {
    .image-zoomer>.slick-slider>.slick-next {
        left: 90%
    }
}

@media only screen and (max-width:400px) {
    .image-zoomer>.slick-slider>.slick-next {
        left: 85%
    }
}

.table-manage {
    overflow-x: scroll;
}

.table-manage-users {
    width: 500px;
    align-self: center;
}

.table-manage-users td,
th {
    text-align: center;
}

@media only screen and (max-width:600px) {
    .table-manage-users {
        width: 280px;
    }

    .About-us-content {
        padding: 0% 6% 6% 5% !important;
    }

    .responsive-design {
        width: 100%;
        position: absolute;
        bottom: 10vh;
        left: 0;
        height: 50vh !important;
    }
}

@media only screen and (max-width:500px) {

    .responsive-design {
        width: 100%;
        position: absolute;
        bottom: 8vh;
        left: 0;
        height: 50vh !important;
    }
}

.pad-left-div>div {
    padding-left: 10% !important;
}

.pad-left-div>input {
    padding-left: 10% !important;
}

.overflow-auto {
    overflow-x: auto;
}

.pswp-gallery {
    /* height: 100%; */
}

@media only screen and (max-width:768px) {
    .pswp-thumbnail {
        /* flex-basis: 25% !important; */
        margin: 15px 10px;
    }
}

@media only screen and (max-width:600px) {
    .pswp-thumbnail {
        /* flex-basis: 35% !important; */
        margin: 15px 10px;
    }
}

@media only screen and (max-width:400px) {
    .pswp-thumbnail {
        /* flex-basis: 42% !important; */
        margin: 15px 10px;
    }
}

@media only screen and (min-width:768px) {
    .pswp-gallery>.pswp-thumbnails {
        align-items: center !important;
    }
}

/* .pswp-gallery>.pswp-thumbnails {
    overflow-y: hidden;
    overflow-x: scroll !important;
    height: 80%;
    position: absolute;
    bottom: 7%;
    display: flex;
    flex-wrap: wrap;

    align-items: flex-start;
    justify-content: space-between;
} */

.hidden-el {
    display: none;
}

.pswp-thumbnail {
    /* border: 1px solid white; */
    object-fit: cover;
    padding: 3px;
    flex-shrink: 0;
    /* flex-basis: 20%; */
    margin: 15px 10px;
    height: 100%;
}

.hidden-photoSwipe {
    display: none;
}

.images-small {
    overflow-x: scroll;
    position: relative !important
}

.overflow-y-allowed {
    overflow: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.overflow-y-allowed::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.slide-down {
    top: 100% !important;
}

/* .pswp__img {
    position: relative !important;
    height: auto !important;
} */

.z-index-custom-5 {
    z-index: 5;
}

/* .pswp__container {
    height: 100vh;
    z-index: 20;
} */

.mt-20 {
    /* margin-top: 20%; */
    margin-top: 42vh;
    /* position: relative; */
}

.mt-20>div {
    /* top: 32vh;
    position: relative; */
}

.pswp__top-bar {
    z-index: 21;
}

.pswp__button--arrow--right,
.pswp__button--arrow--left,
.pswp__button {
    z-index: 1600 !important;
    /* position: absolute !important;
    opacity: 1 !important; */
}

.pswp__button--arrow--right,
.pswp__button--arrow--left {
    position: absolute !important;
    opacity: 1 !important;
}

.nav-header-responsive-active {
    /* overflow-y: scroll !important; */
}

.z-index-back-im {
    z-index: -1 !important;
}

.hide-user-nav {
    z-index: -5;
    position: relative;
}

.blue-icon {
    fill: var(--blue) !important
}


.icons-and-text {
    flex-direction: column;
    align-items: center;
}

.hide-user-nav-opacity {
    /* opacity: 0;
    z-index: -5;
    position: relative; */
    /* margin-top: 16vh !important; */
}

.pswp__zoom-wrap {
    justify-content: center;
    align-items: center;
    display: flex !important;
    height: 89vh !important;
    top: 5% !important;
    transform: none !important;
}

/* .pswp__container {
    height: 100%;
} */

.height-77-vh {
    height: 77vh;
}

.min-240 {
    min-height: 240px;
}

.max-35 {
    max-height: 35px;
    min-height: 38px;
}

.pswp-thumbnail>img {
    object-fit: cover !important;
}


.pswp-thumbnail {
    flex-basis: 60%;
    height: 100%;
}

.pswp__img {
    object-fit: contain;
    width: 100% !important;
    height: 100% !important;
    margin: auto;
    display: flex;
    justify-self: center;
    align-items: center;
}

.pswp-thumbnail>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.no-content::before,
.no-content::after {
    content: none !important;
}

.hide {
    display: none !important;
}

.search-map {
    position: absolute;
    top: 3vh;
    left: 0;
    /* left: 1rem; */
    color: black;
    z-index: 10;
    margin: 0;
    padding: 0;
}

.not-allowed * {
    cursor: not-allowed;
}

.post-type-button-wrapper>div {
    margin: 5px 0;
}

.card-tags {
    display: flex;
}

.show-map-button {
    margin-top: 5px
}

.bold {
    font-weight: 700;
}

div>div>.show-map-checkbox {
    width: 20px;
    height: 20px;
    margin: 0 !important;
    margin-top: 0 !important;
    margin-left: 10px !important;
}

.sponsored-group {
    background-color: lightgrey;
}

.star-sponsor {
    position: absolute;
    z-index: 10;
    left: 10px;
    top: 10px;
}

.card-post {
    position: relative;
    overflow: hidden;
}

.sponsor-line {
    position: absolute;
    width: 100px;
    height: 20px;
    z-index: 10;
    background-color: yellow;
    right: -20px;
    transform: rotate(45deg);
}

.login-wrap-container {
    left: 40%;
    width: 60%;
    position: relative;
    background-color: white;
    /* transition: 0.3s ease-in-out; */
    transition: 0.2s ease-in;
}

.login-wrapper {
    width: 70% !important;
}

.blue-underline {
    border-bottom: 4px solid var(--blue);
}


.purple-underline {
    border-bottom: 4px solid var(--purple);
}

.purple-underline>.active-button-add {
    background-color: var(--purple) !important;
    border-left: 4px sold var(--purple) !important;
    border-right: 4px sold var(--purple) !important;
}

.purple-underline>.main-button:hover {
    border-left: 4px solid var(--purple) !important;
    border-right: 4px solid var(--purple) !important;
}

.orange-underline {
    border-bottom: 4px solid var(--orange);
}

.orange-underline>.active-button-add {
    background-color: var(--orange) !important;
    border-left: 4px sold var(--orange) !important;
    border-right: 4px sold var(--orange) !important;
}

.orange-underline>.main-button:hover {
    border-left: 4px solid var(--orange) !important;
    border-right: 4px solid var(--orange) !important;
}



.main-button {
    border: none;
    background: white;
    flex-grow: 1;
    height: 50px;
    transition: 0.2s ease-in-out;
    border-left: 4px solid white;
    border-right: 4px solid white;
}

.search-btn {
    height: 40px;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
}

.main-button:hover {
    border-left: 4px solid var(--blue);
    border-right: 4px solid var(--blue);
    transition: 0.2s ease-in-out;
}

.main-button.active-button-add {
    background-color: var(--blue);
    color: white !important;
    border-left: 4px solid var(--blue);
    border-right: 4px solid var(--blue);
}

.selectable-div {
    border: 1px solid var(--light-gray);
    border-radius: 50%;

}

.selectable-div,
.active-div {
    height: 20px;
    width: 20px;
    border-radius: 50%;
}

.active-div {

    background-color: var(--blue);
    padding: px;
}

/* .active-div::before{
    content: "";
    border: 1px solid var(--blue);
    height: 24px;
    width: 24px;
    border-radius: 50%;
} */

.main-shadow {
    box-shadow: -2px 7px 13px 6px #aaaaaa
}

.divider.default.text {
    color: rgb(153, 153, 153) !important
}


.account-dropdown>div {
    color: var(--light-gray) !important;
}

.border-form {
    border: 1px solid rgb(222, 222, 223);
    border-radius: 3px;
    margin: 0 3px;
}

.custom-button {
    width: 20vw;
    height: 6vh;
    border-radius: 10px;
}

.add-button-plus-icon {
    background-color: white;
    border-radius: 50%;
    color: var(--blue);
    position: absolute;
    left: 4%;
}

.input-search {
    background-color: var(--blue) !important;
    color: white !important;
}

.left-text {
    text-align: start;
}

.left-side {
    margin-left: 2% 0 !important;
}

.transparent {
    background-color: rgba(0, 0, 0, 0);
}

.sticky-pos {
    position: sticky !important;
}

@media only screen and (max-width:600px) {
    .About-us-main {
        background-position: -200px !important;
    }
}

.divider-line {
    height: 0.5px;
    width: 100%;
    background-color: var(--light-gray);
    margin: 5px 0;
}

.hidden-group.form-control{display: none !important;}

.file-input-container{
    width: 100%;
    /* padding: 3% 0%; */
    height: 200px;
    display: flex;
    align-items: center;
    background-color: var(--light-background);
}
.file-input-inner-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    margin: auto;
}

.llogaria-button *{
    cursor: pointer !important;
}


.city-checkbox .ui.label{
    position: relative;
}

.city-checkbox .delete{
    right:10px;
    z-index: 100;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.city-checkbox .delete:before,.city-checkbox .delete:after{
    position: absolute;
    /* left: 100%; */
    top:-20px;
    content: '';
    width: 15px;
    height: 4px; /* cross thickness */
    background-color: black !important;
}

.city-checkbox .delete:before{
    transform: rotate(45deg);
}
.city-checkbox .delete:after{
    transform: rotate(-45deg);
}

.lang-wrapper{
    bottom: 1rem;
    right: 1rem;
}

.btn-lang{
    background-color: white;
}